<template>
    <div class="bg-gray-50 py-8">
        <section class="container-xl">
            <h2 class="text-3xl font-medium mb-4">{{ $t("Home.FeaturedPost") }}</h2>
            <div class="flex flex-col md:flex-row justify-between gap-8">
                <div class="w-full md:w-1/2">
                    <a
                        :href="useBlogUrl(featuredPost.path)"
                        class="w-full"
                        @click="logBlogPostClick(featuredPost.path)">
                        <img
                            :src="postImage"
                            :alt="featuredPost.title"
                            class="w-full h-auto aspect-video object-contain" />
                    </a>
                </div>
                <div class="w-full md:w-1/2">
                    <h3 class="text-3xl font-medium">
                        <a :href="useBlogUrl(featuredPost.path)" @click="logBlogPostClick(featuredPost.path)">{{
                            featuredPost.title
                        }}</a>
                    </h3>
                    <p class="my-4">{{ featuredPost.description }}</p>
                    <div v-if="recentPosts.length" id="recent-posts">
                        <b class="pb-2">{{ $t("Home.RecentArticles") }}</b>
                        <div>
                            <ul>
                                <li v-for="post in recentPosts" :key="post.path">
                                    <a
                                        :href="useBlogUrl(post.path)"
                                        class="block py-2"
                                        @click="logBlogPostClick(post.path)">
                                        {{ post.title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup lang="ts">
import recentPostsData from "./recent-posts.json";
const { gtag } = useGtag();
const { locale } = useI18n();

const [featuredPost, ...recentPosts] = recentPostsData[locale.value];

const postImage = computed(() => {
    return useBlogUrl(featuredPost.image);
});

const logBlogPostClick = (path: string) => {
    const title = featuredPost.path === path ? featuredPost.title : recentPosts.find((x) => x.path === path)?.title;
    gtag("event", "home_featured_blog_post", { post_name: title });
};
</script>
